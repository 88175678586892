import React from 'react';

import ArrowLink, { ArrowLinkProps } from 'Elements/Link/Arrow';

import BENFeaturedCard, { BENFeaturedCardProps } from 'Components/Card/BEN/Featured';
import BENCard, { BENCardProps } from 'Components/Card/BEN';

import { Listing, ListItem, ListItemFeatured } from './News.styled';

export interface Props {
    heading: string;
    viewAllLink: ArrowLinkProps;
    featuredItem?: BENFeaturedCardProps;
    items: BENCardProps[];
}

const News: React.FC<Props> = props => {
    const { heading, featuredItem, items, viewAllLink } = props;

    return (
        <div>
            <h2>{heading}</h2>
            <ArrowLink {...viewAllLink} />
            <Listing>
                {featuredItem && (
                    <ListItemFeatured>
                        <BENFeaturedCard {...featuredItem} />
                    </ListItemFeatured>
                )}
                {items.map(item => (
                    <ListItem key={item.id}>
                        <BENCard {...item} />
                    </ListItem>
                ))}
            </Listing>
        </div>
    );
};

export default News;
