import React from 'react';

import Grid, { Col } from 'Components/Grid';
// import ArrowLink, { Props as ArrowLinkProps } from 'Elements/Link/Arrow';
import { Props as ArrowLinkProps } from 'Elements/Link/Arrow';

import { Wrapper, Container, Heading, Links, ArrowLink } from './QuickLinks.styled';

interface QuickLinkButtonProps extends ArrowLinkProps {
    id: string;
}
export interface Props extends React.HTMLAttributes<HTMLElement> {
    heading: string;
    links: QuickLinkButtonProps[];
}

const QuickLinks: React.FC<Props> = props => {
    const { heading, links, ...rest } = props;

    return (
        <Wrapper {...rest}>
            <Grid>
                <Col>
                    <Container>
                        <Heading>{heading}</Heading>
                        <Links>
                            {links.map(({ id, ...props }) => (
                                <li key={id}>
                                    <ArrowLink {...props} />
                                </li>
                            ))}
                        </Links>
                    </Container>
                </Col>
            </Grid>
        </Wrapper>
    );
};

export default QuickLinks;
