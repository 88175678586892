import styled, { css } from 'styled-components';
import BaseArrowLink from 'Elements/Link/Arrow';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        background-color: ${theme.colors.tertiary.hex};
    `}
`;

export const Container = styled.div`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-content: center;
        height: 10rem;
        align-items: center;

        @media ${theme.breakpoints.medium.media} {
            grid-template-columns: 1fr;
            height: 8rem;
        }

        @media ${theme.breakpoints.large.media} {
            grid-template-columns: 5fr 7fr;
            height: 6rem;
        }
    `}
`;

export const Heading = styled.h2`
    ${({ theme }) => css`
        font-family: ${theme.fonts.secondary};
        font-size: 1.5rem;
        font-family: ${theme.fonts.secondary};
        font-weight: 300;
        color: ${theme.colors.primaryContrast.hex};
        margin-top: 0;

        @media ${theme.breakpoints.medium.media} {
            text-align: center;
            font-size: 2rem;
            margin-bottom: 1.5rem;
        }

        @media ${theme.breakpoints.large.media} {
            text-align: left;
            font-size: 2rem;
            margin-bottom: 0;
        }
    `}
`;

export const Links = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        @media ${theme.breakpoints.medium.media} {
            align-items: center;
            flex-direction: row;
        }

        @media ${theme.breakpoints.large.media} {
            align-items: left;
        }
    `}
`;

export const ArrowLink = styled(BaseArrowLink)`
    ${({ theme }) => {
        const colors = [
            theme.colors.accents[4].hex,
            theme.colors.accents[1].hex,
            theme.colors.accents[6].hex
        ];
        return colors.map(
            (color, index) => css`
                li:nth-child(${index + 1}) & {
                    color: ${color};
                    margin-bottom: 0.75rem;

                    @media ${theme.breakpoints.large.media} {
                        margin-bottom: 0;
                    }

                    svg {
                        color: ${theme.colors.tertiary.hex};
                    }
                }
            `
        );
    }}
`;
