import styled, { css } from 'styled-components';

export const Listing = styled.ul`
    ${({ theme }) => css`
        grid-template-columns: repeat(12, 1fr);
        list-style: none;
        display: grid;
        gap: 1.5rem;
        padding: 0;
        margin: 1rem 0 0;

        @media ${theme.breakpoints.large.media} {
            margin: 1.5rem 0 0;
        }
    `}
`;

export const ListItem = styled.li`
    ${({ theme }) => css`
        grid-column-start: span 12;

        @media ${theme.breakpoints.medium.media} {
            grid-column-start: span 6;
        }

        @media ${theme.breakpoints.large.media} {
            grid-column-start: span 3;
        }
    `}
`;

export const ListItemFeatured = styled.li`
    ${({ theme }) => css`
        grid-column-start: span 12;

        @media ${theme.breakpoints.large.media} {
            grid-column-start: span 6;
        }
    `}
`;
