import styled, { css } from 'styled-components';

import { Chevron } from '@solent-university/solent-icons';

export const Heading = styled.h2`
    margin-top: 0;
`;

export const EmblaCarousel = styled.div`
    ${({ theme }) => css`
        overflow: hidden;
        padding-top: 2rem;

        @media ${theme.breakpoints.medium.media} {
            padding: 1rem 0 0.1rem 0.1rem;
        }
    `}
`;

export const EmblaContainer = styled.div`
    display: flex;
`;

export const Slide = styled.div`
    ${({ theme }) => css`
        margin-right: 1rem;
        flex: 0 0 75%;

        @media ${theme.breakpoints.medium.media} {
            flex: 0 0 40%;
        }

        @media ${theme.breakpoints.large.media} {
            flex: 0 0 27.5%;
        }
    `}
`;

export const Controls = styled.div`
    ${({ theme }) => css`
        position: relative;
        margin-top: 1.5rem;
        height: 2rem;

        @media ${theme.breakpoints.large.media} {
            margin-top: 2rem;
        }
    `}
`;

export const PrevNextWrapper = styled.div`
    ${({ theme }) => css`
        position: absolute;
        right: 0;

        @media ${theme.breakpoints.medium.media} {
            right: auto;
            left: 0;
        }
    `}
`;

const controlButtonStyles = ({ theme }) => css`
    background: ${theme.colors.tertiary.hex};
    padding: 0;
    border: 0;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:hover {
        cursor: pointer;
        transform: scale(1.05);
    }

    &:focus {
        transform: scale(1.05);
    }

    @media ${theme.breakpoints.large.media} {
        width: 2rem;
        height: 2rem;
    }
`;

export const PrevButton = styled.button`
    ${controlButtonStyles}
`;

export const NextButton = styled.button`
    ${({ theme }) => css`
        ${controlButtonStyles}
        margin-left: 0.75rem;

        @media ${theme.breakpoints.medium.media} {
            margin-left: 0.35rem;
        }
    `}
`;

const chevronStyles = ({ theme }) => css`
    width: 0.8rem;
    height: 0.8rem;
    color: ${theme.colors.primaryContrast.hex};

    @media ${theme.breakpoints.large.media} {
        width: 1.1rem;
        height: 1.1rem;
    }
`;

export const PrevIcon = styled(Chevron)`
    ${chevronStyles}
    transform: rotate(180deg);
    margin-right: 0.25em;
`;

export const NextIcon = styled(Chevron)`
    ${chevronStyles}
    margin-left: 0.25em;
`;

export const Pagination = styled.ul`
    ${({ theme }) => css`
        display: flex;
        justify-content: flex-start;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;

        @media ${theme.breakpoints.medium.media} {
            justify-content: flex-end;
        }
    `}
`;

export const PaginationButton = styled.button<{ $inView: boolean }>`
    ${({ theme, $inView }) => css`
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        margin: 0 0.1rem;
        padding: 0;
        border: 0.1rem solid ${theme.colors.tertiary.hex};
        background: ${$inView
            ? css`
                  ${theme.colors.tertiary.hex}
              `
            : css`
                  ${theme.colors.primaryContrast.hex}
              `};

        &:hover {
            cursor: pointer;
            transform: scale(1.1);
        }

        &:focus {
            transform: scale(1.1);
        }
    `}
`;
