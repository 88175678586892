import React, { useState } from 'react';
import { StaticImageData } from 'next/image';

import { LinkProps } from 'Elements/Link';

import { IconContainer } from 'Elements/Link/Arrow/Arrow.styled';

import {
    CardStyled,
    ImageContainer,
    StyledImage,
    Tag,
    Inner,
    Heading,
    CardLink,
    Description,
    Date,
    Cta,
    ArrowIcon
} from './BEN.styled';

export interface Props {
    id: string;
    image: {
        alt?: string;
        src: StaticImageData | string;
    };
    tag?: string;
    heading: string;
    description?: string;
    date: string;
    link: LinkProps;
}

const BEN: React.FC<Props> = props => {
    const { id, image, tag, heading, description, date, link } = props;
    const [isHoverActive, setIsHoverActive] = useState(false);

    return (
        <CardStyled
            className="c-card-ben"
            onMouseEnter={() => setIsHoverActive(true)}
            onMouseLeave={() => setIsHoverActive(false)}
        >
            <ImageContainer>
                <StyledImage
                    src={image.src}
                    alt={image.alt || ''}
                    fill
                    $isHovered={isHoverActive}
                />
            </ImageContainer>
            {tag && <Tag>{tag}</Tag>}
            <Inner>
                <Heading $isHovered={isHoverActive}>
                    <CardLink
                        id={id}
                        href={link.href}
                        aria-label={`Read more on ${heading}`}
                        variant="secondary"
                        data-hover={isHoverActive}
                    >
                        {heading}
                    </CardLink>
                </Heading>
                {description && <Description $isHovered={isHoverActive}>{description}</Description>}
                {date && <Date $isHovered={isHoverActive}>{date}</Date>}
                <Cta as="span" aria-hidden="true">
                    Read article
                    <IconContainer $outline $isHovered={isHoverActive}>
                        <ArrowIcon $outline />
                    </IconContainer>
                </Cta>
            </Inner>
        </CardStyled>
    );
};

export default BEN;
