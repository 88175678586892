import slugify from 'slugify';
import dynamic from 'next/dynamic';
import { format, parseISO } from 'date-fns';
import type { Entry, PageEntry } from 'contensis-delivery-api';
import HTMLReactParser from 'html-react-parser';

import cmsParser from 'Common/util/cmsParser';
import { getSlugFromContentType } from 'Common/util/ben';

import type { Props } from './Homepage';

export type PageData = {
    entry: PageEntry;
    news: {
        featured: Entry;
        items: Entry[];
    };
    events: Entry[];
    courses: Entry[];
};

const datamap = (data: PageData): Props => {
    const { entry, news, events, courses } = data;

    const featuredNews = news.featured;
    const standardNews = news.items;

    return {
        heroBanner: {
            useCourseSearch: entry.flexibleHeroBannerBuilder.useCourseSearch,
            heading: entry.flexibleHeroBannerBuilder.heading,
            button:
                entry.flexibleHeroBannerBuilder.buttonComposer.length >= 1
                    ? cmsParser.linkButton(entry.flexibleHeroBannerBuilder.buttonComposer[0].value)
                    : undefined,
            postHeading:
                entry.flexibleHeroBannerBuilder.subheading &&
                entry.flexibleHeroBannerBuilder.subheading,
            searchCourse: entry.flexibleHeroBannerBuilder.useCourseSearch
                ? {
                      placeholder: 'Search for a course',
                      search: {
                          href: '/courses?search={{search}}',
                          ariaLabel: 'Submit your course search'
                      },
                      label: 'Course search',
                      fetchResults: (
                          value: string
                      ): { id: string; label: string; href: string }[] =>
                          value === ''
                              ? []
                              : courses
                                    .filter(course => course.name.toLowerCase().includes(value))
                                    .map(course => ({
                                        id: course.sys.id,
                                        label: `${
                                            course.degreeType && course.degreeType !== 'none'
                                                ? course.degreeType + ' '
                                                : ''
                                        }${course.name}`,
                                        href: course.sys.uri
                                    }))
                  }
                : undefined,

            imageThemeBuilder: entry.flexibleHeroBannerBuilder.imageAndThemeBuilder.map(item => ({
                desktop: cmsParser.image(item.desktopBackgroundImage),
                mobile: cmsParser.image(item.mobileBackgroundImage),
                textColor: item.textColour && cmsParser.getThemeColour(item.textColour),
                searchColor: item.searchColour && cmsParser.getThemeColour(item.searchColour),
                buttonVariant: item.buttonVariant && item.buttonVariant
            }))
        },
        quickLinks: {
            heading: entry.quickLinksHeading,
            links: entry.quickLinks.map(link => ({
                id: slugify(link.label, { lower: true }),
                ...cmsParser.link(link),
                prefetch: false
            }))
        },
        generalPromoPoint: !entry.toggleTEFAwardPromo
            ? {
                  heading: entry.promoPointHeading,
                  text: entry.promoPointDescription,
                  link: entry.promoPointButton
                      ? { ...cmsParser.linkButton(entry.promoPointButton), prefetch: false }
                      : undefined,
                  image: entry.promoPointImage ? cmsParser.image(entry.promoPointImage) : undefined
              }
            : undefined,
        tefAward: entry.toggleTEFAwardPromo
            ? {
                  heading: entry.promoPointHeading,
                  text: entry.promoPointDescription,
                  link: entry.promoPointButton
                      ? { ...cmsParser.linkButton(entry.promoPointButton), prefetch: false }
                      : undefined,
                  image: cmsParser.image(entry.tefImage),
                  showBackground: true
              }
            : undefined,
        informationGrid: {
            items: entry.secondaryPromoPoint.map(item => ({
                icon: dynamic(() =>
                    import('@solent-university/solent-icons').then(icons => icons[item.icon.name])
                ),
                heading: item.heading,
                headingType: 'h2',
                text: item.description,
                link: cmsParser.link({ ...item, label: 'Find out more' })
            }))
        },
        keyStats: {
            keyStats: entry.keyFacts.map(({ number, fact, description }, index) => ({
                id: index,
                statValue: number,
                stat: fact.replace('{{', '{{{').replace('}}', '}}}'),
                description
            })),
            link: cmsParser.linkButton(entry.keyFactsLink),
            heading: entry.keyFactsHeading,
            headingType: 'h3'
        },
        gridNavigation: {
            items: entry.gridNavigationCards.map(item => ({
                ...cmsParser.link({ label: item.heading, ...item }),
                id: slugify(item.heading, { lower: true }),
                image: item?.image ? cmsParser.image(item.image, '/cms') : undefined,
                variant: cmsParser.getLinkVariant(item.linkVariantPicker.linkVariantPicker),
                backgroundColour: cmsParser.getThemeColour(item.backgroundColour.colourPicker)
            }))
        },
        virtualTour: {
            heading: entry.virtualTourLabel,
            text: entry.virtualTourCopyText,
            fileSize: entry.virtualTourSize,
            button: {
                ariaLabel: entry.virtualTourAriaLabel,
                label: entry.virtualTourLabel
            },
            embed: {
                title: entry.virtualTourLabel,
                src: entry.virtualTourEmbed
            }
        },
        benMiniListing: {
            newsSectionId: 'news',
            news: {
                heading: 'The latest news',
                viewAllLink: {
                    label: 'See all',
                    ariaLabel: 'See all news',
                    href: '/news'
                },
                featuredItem: {
                    id: featuredNews.sys.id,
                    image: cmsParser.image(featuredNews.heroImage, '/cms/ben'),
                    heading: featuredNews.title,
                    description: featuredNews.description,
                    date: format(parseISO(featuredNews.publishDate), 'd MMMM yyyy'),
                    link: {
                        href: `/news/${featuredNews.sys.slug}`,
                        label: 'Read article'
                    }
                },
                items: standardNews.map(item => ({
                    id: item.sys.id,
                    image: cmsParser.image(item.heroImage, '/cms/ben'),
                    heading: item.title,
                    tag: getSlugFromContentType(item.sys.contentTypeId),
                    description: item.description,
                    date: format(parseISO(item.publishDate), 'd MMMM yyyy'),
                    link: {
                        href: `/news/${item.sys.slug}`,
                        label: 'Read article'
                    }
                }))
            },
            eventsSectionId: 'events',
            events: {
                id: 'home-event-carousel',
                heading: 'Events',
                viewAllLink: {
                    label: 'See all',
                    ariaLabel: 'See all events',
                    href: '/events'
                },
                items: events.map(item => ({
                    id: `eventCard_${item.sys.id}`,
                    link: {
                        label: item.title,
                        href: `/events/${item.sys.slug}`
                    },
                    date: format(parseISO(item.eventDate.from), 'd MMMM yyyy')
                }))
            }
        },
        footerVisit: {
            heading: entry.falseFooter.heading,
            children: HTMLReactParser(entry.falseFooter.copyText),
            link: cmsParser.link(entry.falseFooter.link),
            video: cmsParser.transcriptEmbed(entry.falseFooter.videoTranscript),
            videoButton: {
                label: 'Southampton',
                ariaLabel: 'Watch a video about Southampton'
            }
        }
    };
};

export default datamap;
