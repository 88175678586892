export const getSlugFromContentType = (contentType: string): string => {
    let slug = '';

    switch (contentType) {
        case 'blogArticle':
            slug = 'blogs';
            break;
        case 'event':
            slug = 'events';
            break;
        case 'videoArticle':
            slug = 'videos';
            break;
        default:
            slug = 'news';
            break;
    }

    return slug;
};

export const getContentTypeFromSlug = (slug: string): string => {
    let contentType = '';

    switch (slug) {
        case 'blogs':
            contentType = 'blogArticle';
            break;
        case 'events':
            contentType = 'event';
            break;
        case 'videos':
            contentType = 'videoArticle';
            break;
        default:
            contentType = 'newsArticle';
            break;
    }

    return contentType;
};
