import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';
import { colord } from 'colord';

export const CardStyled = styled.article`
    ${({ theme }) => css`
        position: relative;
        padding: 0.75rem 1rem 0.5rem;
        color: ${theme.colors.primaryContrast.hex};
        border-radius: 0.5rem;
        overflow: hidden;
        z-index: 1;

        &:hover {
            cursor: pointer;
        }

        @media ${theme.breakpoints.medium.media} {
            padding: 4rem 8rem 1rem 1.5rem;
        }

        @media ${theme.breakpoints.large.media} {
            padding: 18rem 3rem 1rem 1.5rem;
        }
    `}
`;

export const ImageContainer = styled.div`
    ${({ theme }) => css`
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
            background: linear-gradient(
                249deg,
                transparent 0%,
                ${colord(theme.colors.tertiary.hex).alpha(0.725).toHex()} 20%,
                ${colord(theme.colors.tertiary.hex).alpha(0.9).toHex()} 55%,
                ${theme.colors.tertiary.hex} 100%
            );
        }

        @media ${theme.breakpoints.large.media} {
            &:before {
                background: linear-gradient(
                    180deg,
                    transparent 0%,
                    ${colord(theme.colors.tertiary.hex).alpha(0.85).toHex()} 52%,
                    ${theme.colors.tertiary.hex} 100%
                );
            }
        }
    `}
`;

export const Heading = styled(animated.h3)<{ $isHovered: boolean }>`
    ${({ theme, $isHovered }) => css`
        font-size: 1.125rem;
        font-weight: bold;
        line-height: 1.25;
        max-width: 75%;

        ${$isHovered &&
        css`
            text-decoration: underline;
            text-decoration-thickness: 0.075em;
        `}

        @media ${theme.breakpoints.medium.media} {
            font-size: 1.5rem;
            max-width: none;
        }

        @media ${theme.breakpoints.large.media} {
            font-size: 2rem;
        }
    `}
`;

export const DateCTAWrapper = styled.div`
    ${({ theme }) => css`
        margin-top: 3rem;
        display: flex;
        justify-content: space-between;

        @media ${theme.breakpoints.medium.media} {
            display: block;
            margin-top: 0;
        }
    `}
`;
