import React, { useState, useCallback, useEffect } from 'react';
import { useTheme } from 'styled-components';
import useEmblaCarousel from 'embla-carousel-react';
import type { EmblaCarouselType } from 'embla-carousel';

import ArrowLink, { ArrowLinkProps } from 'Elements/Link/Arrow';

import Grid, { Col } from 'Components/Grid';
import BENEventCard, { BENEventCardProps } from 'Components/Card/BEN/Event';

import {
    Heading,
    EmblaCarousel,
    EmblaContainer,
    Slide,
    Controls,
    PrevNextWrapper,
    PrevButton,
    NextButton,
    PrevIcon,
    NextIcon,
    Pagination,
    PaginationButton
} from './Events.styled';

export interface Props {
    id: string;
    heading: string;
    viewAllLink: ArrowLinkProps;
    items: BENEventCardProps[];
}

const Events: React.FC<Props> = props => {
    const { id, heading, viewAllLink, items } = props;

    const [emblaRef, emblaApi] = useEmblaCarousel({
        loop: true,
        align: 'start',
        inViewThreshold: 0.8
    });
    const [slidesInView, setSlidesInView] = useState<number[]>([]);

    const theme = useTheme();
    const colours = [
        theme.colors.accents[4].hex,
        theme.colors.accents[3].hex,
        theme.colors.accents[6].hex,
        theme.colors.accents[8].hex
    ];

    const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
    const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);
    const scrollTo = useCallback(
        (index: number) => emblaApi && emblaApi.scrollTo(index),
        [emblaApi]
    );

    const onSlidesInView = useCallback((emblaApi: EmblaCarouselType) => {
        setSlidesInView(emblaApi.slidesInView());
    }, []);

    useEffect(() => {
        if (!emblaApi) return;

        onSlidesInView(emblaApi);
        emblaApi.on('slidesInView', onSlidesInView);
    }, [emblaApi, onSlidesInView]);

    return (
        <Grid>
            <Col m={2}>
                <Heading>{heading}</Heading>
                <ArrowLink {...viewAllLink} />
            </Col>
            <Col m={10}>
                <EmblaCarousel ref={emblaRef}>
                    <EmblaContainer>
                        {items.map((item, index) => (
                            <Slide key={item.id}>
                                <BENEventCard {...item} colour={colours[index % colours.length]} />
                            </Slide>
                        ))}
                    </EmblaContainer>
                </EmblaCarousel>
                <Controls>
                    <PrevNextWrapper>
                        <PrevButton aria-label="Previous event" onClick={scrollPrev}>
                            <PrevIcon />
                        </PrevButton>
                        <NextButton aria-label="Next event" onClick={scrollNext}>
                            <NextIcon />
                        </NextButton>
                    </PrevNextWrapper>
                    <nav aria-label={`${id} nav`}>
                        <Pagination>
                            {items.map((item, index) => (
                                <li key={item.id}>
                                    <PaginationButton
                                        aria-label={`Go to slide ${index + 1}`}
                                        aria-current={slidesInView.includes(index)}
                                        $inView={slidesInView.includes(index)}
                                        onClick={() => scrollTo(index)}
                                    />
                                </li>
                            ))}
                        </Pagination>
                    </nav>
                </Controls>
            </Col>
        </Grid>
    );
};

export default Events;
