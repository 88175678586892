import React from 'react';

import Section from 'Elements/Section';

import Grid, { Col } from 'Components/Grid';

import News, { NewsMiniListingProps } from './News';
import Events, { EventsMiniListingProps } from './Events';

export interface Props {
    newsSectionId: string;
    news: NewsMiniListingProps;
    eventsSectionId: string;
    events: EventsMiniListingProps;
}

const Ben: React.FC<Props> = props => {
    const { newsSectionId, news, eventsSectionId, events } = props;

    return (
        <>
            <Section id={newsSectionId}>
                <Grid>
                    <Col>
                        <News {...news} />
                    </Col>
                </Grid>
            </Section>
            <Section id={eventsSectionId} paddingTop={1}>
                <Grid>
                    <Col>
                        <Events {...events} />
                    </Col>
                </Grid>
            </Section>
        </>
    );
};

export default Ben;
