import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { useSpring } from '@react-spring/web';

import { LinkProps } from 'Elements/Link';

import { Container, Expandable, Heading, StyledLink, Date } from './Event.styled';

export interface Props {
    id: string;
    link: Omit<LinkProps, 'label'> & { label: string };
    date?: string;
    colour?: string;
    tabIndex?: number;
}

const Event: React.FC<Props> = props => {
    const theme = useTheme();
    const { id, link, date, colour = theme.colors.accents[3].hex, tabIndex } = props;

    const [isHovered, setIsHovered] = useState(false);

    const expandHeight = '1rem';
    const config = {
        duration: 250
    };

    const expandSpring = useSpring({
        paddingTop: isHovered ? expandHeight : '0',
        config
    });

    const shiftHeadingSpring = useSpring({
        marginTop: isHovered ? `-${expandHeight}` : '0',
        marginBottom: isHovered ? expandHeight : '0',
        config
    });

    return (
        <Container>
            <Expandable style={expandSpring} />
            <Heading $colour={colour} style={shiftHeadingSpring}>
                <StyledLink
                    {...link}
                    ariaLabel={`Read more on ${link.label}`}
                    id={id}
                    tabIndex={tabIndex}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onFocus={() => setIsHovered(true)}
                    onBlur={() => setIsHovered(false)}
                    data-hovered={isHovered}
                />
            </Heading>
            {date && <Date>{date}</Date>}
        </Container>
    );
};

export default Event;
