import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';

import Link from 'Elements/Link';

export const Container = styled.div`
    ${({ theme }) => css`
        position: relative;
        height: 100%;
        min-height: 8.5rem;
        background: ${theme.colors.tertiary.hex};
        color: ${theme.colors.primaryContrast.hex};
        border-radius: 0.5rem;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media ${theme.breakpoints.large.media} {
            min-height: 10rem;
            padding: 1rem 1.5rem;
        }
    `}
`;

export const Expandable = styled(animated.div)`
    ${({ theme }) => css`
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${theme.colors.tertiary.hex};
        border-radius: 0.5rem;
        box-sizing: content-box;
        z-index: -1;
    `}
`;

export const Heading = styled(animated.h3)<{ $colour?: string }>`
    ${({ theme, $colour }) => css`
        font-family: ${theme.fonts.secondary};
        font-weight: 300;
        font-size: 1.5rem;
        line-height: 1;
        margin: 0.5rem 0 0;

        ${$colour &&
        css`
            color: ${$colour};
        `}

        @media ${theme.breakpoints.large.media} {
            font-size: 1.875rem;
        }
    `}
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    font-size: inherit;
    color: inherit;

    &:hover,
    &:focus {
        text-decoration: underline;
        text-decoration-thickness: 0.075em;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

export const Date = styled.p`
    font-size: 1rem;
    margin: 0.5rem 0 0;
`;
