import React from 'react';
import cmsParser from 'Common/util/cmsParser';
import setCacheHeaders from 'Common/func/setCacheHeaders';
import contensis from 'Common/api/contensis';
import { Op, OrderBy } from 'contensis-delivery-api';
import { excludeMetadata } from 'Common/util/contensis/api';

import type { NextPageWithLayout, GetServerSideProps } from 'next';

import MainLayout from 'Layout/Main';
import Metadata from 'Components/Metadata';
import Template, { datamap, PageData } from 'Templates/Homepage';

const Home: NextPageWithLayout<PageData> = ({ entry, news, events, courses }) => (
    <>
        <Metadata {...cmsParser.metadata(entry)} />
        <Template {...datamap({ entry, news, events, courses })} />
    </>
);

Home.getLayout = (page, props) => {
    return (
        <>
            <MainLayout {...props} logo="red">
                {page}
            </MainLayout>
        </>
    );
};

export const getServerSideProps: GetServerSideProps<any> = async ({ preview, res }) => {
    const { fetch, surrogateKeys } = contensis({ preview, fetchSurrogateKeys: true });
    const { data, error } = await fetch(async (client, search) => {
        const resPage = await client.nodes.getRoot({
            entryLinkDepth: 2,
            entryFields: ['*', ...excludeMetadata]
        });

        if (!resPage.entry) {
            return { error: true };
        }

        const linkDepth = 3;
        const featuredNewsAndBlogsQuery = [
            Op.equalTo('sys.contentTypeId', 'newsArticle'),
            Op.equalTo('miscellaneousTags', '0/1259/1262/1389/1623')
        ];

        const newsAndBlogsQuery = [
            Op.equalTo('sys.contentTypeId', 'newsArticle'),
            Op.not(Op.equalTo('miscellaneousTags', '0/1259/1262/1389/1623'))
        ];

        const eventsQuery = [
            Op.equalTo('sys.contentTypeId', 'event'),
            Op.greaterThan('eventDate.from', new Date().toISOString())
        ];

        const coursesQuery = Op.or(
            Op.equalTo('sys.contentTypeId', 'courseUndergraduate'),
            Op.equalTo('sys.contentTypeId', 'coursePostgraduate'),
            Op.equalTo('sys.contentTypeId', 'courseApprenticeships'),
            Op.equalTo('sys.contentTypeId', 'courseProfessional'),
            Op.equalTo('sys.contentTypeId', 'courseFoundationYears')
        );

        const [resFeaturedNewsAndBlogs, resNewsAndBlogs, resEvents, resCourses] = await Promise.all(
            [
                search(featuredNewsAndBlogsQuery, {
                    linkDepth,
                    orderBy: OrderBy.desc('publishDate'),
                    pageSize: 1,
                    fields: [
                        'sys.id',
                        'sys.slug',
                        'heroImage',
                        'title',
                        'description',
                        'publishDate'
                    ]
                }),
                search(newsAndBlogsQuery, {
                    linkDepth,
                    orderBy: OrderBy.desc('publishDate'),
                    pageSize: 2,
                    fields: [
                        'sys.id',
                        'sys.slug',
                        'heroImage',
                        'title',
                        'description',
                        'publishDate'
                    ]
                }),
                search(eventsQuery, {
                    linkDepth,
                    orderBy: OrderBy.asc('eventDate.from'),
                    pageSize: 6,
                    fields: ['sys.id', 'sys.slug', 'title', 'eventDate.from']
                }),
                search(coursesQuery, {
                    linkDepth,
                    orderBy: OrderBy.asc('name'),
                    pageSize: 400,
                    fields: ['sys.id', 'sys.uri', 'name', 'degreeType']
                })
            ]
        );

        return {
            entry: resPage.entry,
            news: {
                featured: resFeaturedNewsAndBlogs.items[0],
                items: resNewsAndBlogs.items
            },
            events: resEvents.items,
            courses: resCourses.items.filter(item => item.sys.uri)
        };
    });

    if (error) {
        return { notFound: true };
    } else {
        setCacheHeaders(res, surrogateKeys);

        return { props: data };
    }
};

export default Home;
