import styled, { css } from 'styled-components';
import NextImage from 'next/image';
import { animated } from '@react-spring/web';

import Link from 'Elements/Link';
import { Container, Icon } from 'Elements/Link/Arrow/Arrow.styled';

export const CardStyled = styled.article`
    ${({ theme }) => css`
        position: relative;
        padding: 0;
        border-radius: 0.5rem;
        display: flex;

        @media ${theme.breakpoints.medium.media} {
            display: block;
        }
    `}
`;

export const ImageContainer = styled.div`
    ${({ theme }) => css`
        width: 6rem;
        min-width: 6rem;
        min-height: 6.5rem;
        position: relative;
        border-radius: 0.5rem;
        overflow: hidden;

        @media ${theme.breakpoints.medium.media} {
            width: 100%;
            height: 8.5rem;
        }

        @media ${theme.breakpoints.large.media} {
            height: 10rem;
        }
    `}
`;

export const StyledImage = styled(NextImage)<{ $isHovered: boolean }>`
    ${({ $isHovered }) => css`
        object-fit: cover;
        transition: transform 0.7s ease-in-out;

        ${$isHovered &&
        css`
            transform: scale(1.2);
        `}
    `}
`;

export const Tag = styled.span`
    ${({ theme }) => css`
        display: none;

        @media ${theme.breakpoints.medium.media} {
            display: block;
            font-size: 1.125rem;
            color: ${theme.colors.primaryContrast.hex};
            background: ${theme.colors.tertiary.hex};
            position: absolute;
            top: 1rem;
            left: 0;
            padding: 0.2rem 1.25rem 0.2rem 1.5rem;
            padding-left: 1.5rem;
            border-radius: 0 0.5rem 0.5rem 0;

            ::first-letter {
                text-transform: capitalize;
            }
        }
    `}
`;

export const Inner = styled.div`
    ${({ theme }) => css`
        padding-left: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media ${theme.breakpoints.medium.media} {
            padding-left: 0;
            display: block;
        }
    `}
`;

export const Heading = styled(animated.h3)<{ $isHovered: boolean }>`
    ${({ theme, $isHovered }) => css`
        font-size: 1.125rem;
        font-weight: bold;
        line-height: 1.25;
        color: ${theme.colors.primary.hex};
        margin-top: 0;

        ${$isHovered &&
        css`
            text-decoration: underline;
            text-decoration-thickness: 0.075em;
        `}

        @media ${theme.breakpoints.medium.media} {
            font-size: 1.5rem;
            margin-top: 1rem;
        }

        @media ${theme.breakpoints.large.media} {
            font-size: 1.75rem;
        }
    `}
`;

export const CardLink = styled(Link)`
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    text-decoration: none;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
`;

export const Description = styled(animated.p)<{ $isHovered: boolean }>`
    ${({ theme, $isHovered }) => css`
        display: none;

        ${$isHovered &&
        css`
            text-decoration: underline;
            text-decoration-thickness: 0.075em;
        `}

        @media ${theme.breakpoints.medium.media} {
            display: block;
            font-size: 1rem;
            margin-top: 0.75rem;
        }

        @media ${theme.breakpoints.large.media} {
            font-size: 1.25rem;
        }
    `}
`;

export const Date = styled(animated.p)<{ $isHovered: boolean }>`
    ${({ theme, $isHovered }) => css`
        font-size: 1rem;
        margin: 0;

        ${$isHovered &&
        css`
            text-decoration: underline;
            text-decoration-thickness: 0.075em;
        `}

        @media ${theme.breakpoints.medium.media} {
            margin-bottom: 1rem;
        }
    `}
`;

export const Cta = styled(Container)`
    ${({ theme }) => css`
        color: ${theme.colors.primary.hex};
    `}
`;

export const ArrowIcon = styled(Icon)`
    ${({ theme }) => css`
        color: ${theme.colors.primary.hex};
    `}
`;
