import React, { useState } from 'react';

import {
    Container as ArrowLinkContainer,
    IconContainer,
    Icon
} from 'Elements/Link/Arrow/Arrow.styled';

import type { BENCardProps } from '../';

import { StyledImage, Tag, CardLink, Description, Date } from '../BEN.styled';

import { CardStyled, ImageContainer, Heading, DateCTAWrapper } from './Featured.styled';

export type Props = Omit<BENCardProps, 'tag'>;

const Featured: React.FC<Props> = props => {
    const { id, image, heading, description, date, link } = props;
    const [isHoverActive, setIsHoverActive] = useState(false);

    return (
        <CardStyled
            className="c-card-ben"
            onMouseEnter={() => setIsHoverActive(true)}
            onMouseLeave={() => setIsHoverActive(false)}
        >
            <ImageContainer>
                <StyledImage
                    src={image.src}
                    alt={image.alt || ''}
                    fill
                    $isHovered={isHoverActive}
                />
            </ImageContainer>
            <Tag>Featured</Tag>
            <Heading $isHovered={isHoverActive}>
                <CardLink
                    id={id}
                    href={link.href}
                    aria-label={`Read more on ${heading}`}
                    variant="secondary"
                    data-hover={isHoverActive}
                >
                    {heading}
                </CardLink>
            </Heading>
            {description && <Description $isHovered={isHoverActive}>{description}</Description>}
            <DateCTAWrapper>
                {date && <Date $isHovered={isHoverActive}>{date}</Date>}
                <ArrowLinkContainer as="span" aria-hidden="true">
                    Read article
                    <IconContainer $outline $isHovered={isHoverActive}>
                        <Icon />
                    </IconContainer>
                </ArrowLinkContainer>
            </DateCTAWrapper>
        </CardStyled>
    );
};

export default Featured;
